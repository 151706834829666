import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export type TTranslationRequest = {
    id: string
    type: 'post' | 'comment'
    language: string
}

/**
 * 번역
 * @param {string} id - 번역할 게시물, 댓글의 아이디
 * @param {string} type - 게시물, 댓글
 * @param {string} targetLang - 원하는 언어
 * @returns {function} - 번역 결과
 */
export const apiPostTranslate = async (params: TTranslationRequest): Promise<ApiRes<{content: string}>> => {
    return await apiGet(`translate?id=${params.id}&type=${params.type}&language=${params.language}`)
}
