import React from 'react'

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    className?: string
    sizeExtension?: string
}

const WizImg: React.FC<IProps> = ({className, sizeExtension = '?type=webp&quality=100&action=fit', src, ...props}) => {
    return <img src={`${src}${sizeExtension}`} className={`${className}`} {...props}></img>
}
export default WizImg
