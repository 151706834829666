import {apiGet, apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {SuccessRes} from '@type/common'
import {TBan} from '@api/ban/type'
import {IApiBaseProps} from '@api/user/sidebar/getSidebar'

/**
 * 차단힌 유저 리스트
 * @returns {function} - 차단힌 유저 리스트 결과
 */
export const apiGetBanList = async (): Promise<ApiRes<TBan[]>> => {
    return await apiGet('ban')
}

/**
 * 차단힌 유저 아이디 리스트
 * @returns {function} - 차단힌 유저 아이디 리스트 결과
 */
export const apiGetBanUserIdList = async (props?: IApiBaseProps): Promise<ApiRes<string[]>> => {
    return await apiGet('ban/simple', {cookies: props?.cookie})
}

/**
 * 유저 차단
 * @param {string} ban_user_id - 유저 아이디 파라미터
 * @returns {function} - 유저 차단 결과
 */
export const apiBanUser = async (ban_user_id: string): Promise<ApiRes<SuccessRes>> => {
    return await apiPost(`ban`, {ban_user_id})
}

/**
 * 유저 차단
 * @param {string} ban_user_id - 유저 아이디 파라미터
 * @returns {function} - 유저 차단 해제 결과
 */
export const apiDeleteBanUser = async (ban_user_id: string): Promise<ApiRes<SuccessRes>> => {
    return await apiPost(`ban/unban`, {ban_user_id})
}
