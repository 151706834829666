import React from 'react'
import {ListboxButton} from '@headlessui/react'
import {WSOption} from '@component/select/WizSelectBox'
import Text from '@component/Text/Text'
import IconDown from '@svg/common/ic_btn_down.svg'

interface IProps {
    className?: string
    selectedOption: string | number
    options?: Array<WSOption>
    onChange?: (option?: string | number) => void
    leftIcon?: React.ReactNode
}

const WizTextSelectButtonItem: React.FC<IProps> = ({className, selectedOption, options, onChange, leftIcon}) => {
    return (
        <ListboxButton
            className={`flex items-center gap-x-[5px] px-[10px] py-[7px] border border-transparent hover:border-gray06 hover:dark:border-dark_gray06 rounded-[3px] cursor-pointer ${className}`}>
            {leftIcon}
            <Text
                className={`cursor-pointer text-body2 text-gray01 dark:text-dark_gray01 whitespace-nowrap truncate`}
                enablePreWhiteSpace={false}>
                {selectedOption}
            </Text>
            {options && options.length > 1 ? (
                <IconDown className={'w-[12px] h-[12px] fill-gray02 dark:fill-dark_gray02'} />
            ) : null}
        </ListboxButton>
    )
}
export default WizTextSelectButtonItem
