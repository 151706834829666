import React, {useImperativeHandle, useRef} from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'

import {KAKAO_KEY} from '@constant/auth'

import Text from '@component/Text/Text'
import Script from 'next/script'
import WizIcon from '@component/images/WizIcon'
import WizIconButton from '@component/buttons/WizIconButton'
import WizContentModal from '@component/modal/WizContentModal'

import IconCopyLink from '@svg/common/ic_copy_link.svg'
import XIcon from '@svg/sns/ic_x.svg'
import KakaoIcon from '@svg/sns/ic_kakao.svg'
import TelegramIcon from '@svg/sns/ic_telegram.svg'

interface IProps {
    className?: string
}

const ShareLinkPopup = ({className}: IProps, ref) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const router = useRouter()
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()

    const [path, setPath] = React.useState('')

    useImperativeHandle(ref, () => ({
        show: ({path}: {path: string}) => {
            modalRef.current.show()
            setPath(path)
        },
    }))

    const onCloseModal = () => {
        modalRef?.current?.close()
        setPath('')
    }

    const onClickShare = shareType => {
        switch (shareType) {
            case 'twitter':
                window.open(
                    'https://twitter.com/share?url=' +
                        `${t('modal.share.content.title')} : ${window.location.origin}/${router.locale}${path}`,
                    '_blank',
                    'width=600,height=400',
                )
                onCloseModal()
                break
            case 'kakaotalk':
                if (!window.Kakao.isInitialized()) {
                    window.Kakao.init(KAKAO_KEY)
                }
                window.Kakao.Link.sendDefault({
                    objectType: 'feed',
                    content: {
                        title: t('metaTag.title'),
                        imageUrl: 'https://leagueoftraders.io/images/img_meta_thumbnail.png',
                        link: {
                            mobileWebUrl: `${window.location.origin}/${router.locale}${path}`,
                            webUrl: `${window.location.origin}/${router.locale}${path}`,
                        },
                        imageWidth: 800,
                    },
                    installTalk: true,
                })
                break
            case 'telegram':
                window.open(t('sns.telegram.url'), '_blank')
                onCloseModal()
                break
            case 'mail':
                window.location.href = `mailto:?body=${t('modal.share.content.title')} : ${window.location.origin}/${
                    router.locale
                }${path}`
                onCloseModal()
                break
            case 'link':
                copyToClipboard(`${window.location.origin}/${router.locale}${path}`)
                showSnackbar(t('portfolio.share.result.success'))
                onCloseModal()
                break
        }
    }

    return (
        <>
            <Script strategy={'lazyOnload'} src="/kakao.min.js" crossOrigin={'anonymous'} />
            <WizContentModal
                ref={modalRef}
                size={'400'}
                title={t('modal.share.title')}
                titleClassName={'text-ti1'}
                panelClassName={'py-[30px] sm:!w-[320px] sm:flex-none sm:!rounded-[5px]'}>
                <div className={`py-[20px] grid grid-cols-4 text-center items-center justify-center`}>
                    <WizIconButton showHover={false} onClick={() => onClickShare('link')}>
                        <WizIcon backgroundColorClassName={'bg-gray07 dark:bg-dark_gray07 p-[7px] inline-block'}>
                            <IconCopyLink className={'fill-gray02 dark:fill-dark_gray02 w-[20px]'} />
                        </WizIcon>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.share.url.text')}
                        </Text>
                    </WizIconButton>
                    <WizIconButton showHover={false} onClick={() => onClickShare('twitter')}>
                        <XIcon className={'w-[36px] h-[36px] inline-block'} />

                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('modal.share.x.text')}
                        </Text>
                    </WizIconButton>
                    <WizIconButton showHover={false} onClick={() => onClickShare('kakaotalk')}>
                        <KakaoIcon className={'w-[36px] h-[36px] inline-block'} />
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('modal.share.kakao.text')}
                        </Text>
                    </WizIconButton>
                    <WizIconButton showHover={false} onClick={() => onClickShare('telegram')}>
                        <TelegramIcon className={'w-[36px] h-[36px] inline-block rounded-full'} />
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('modal.share.telegram.text')}
                        </Text>
                    </WizIconButton>
                </div>
            </WizContentModal>
        </>
    )
}

export default React.forwardRef(ShareLinkPopup)
