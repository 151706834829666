import {apiDelete, apiGet, apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {
    Feed,
    FeedType,
    TFeedList,
    TimelineChannel,
    TPost,
    TPostLikeRes,
    TPostRes,
    TPostTrend,
    WritePostParams,
} from '@api/timeline/type'
import {SuccessRes} from '@type/common'
import {IApiBaseProps} from '@api/user/sidebar/getSidebar'

/**
 * 유저 타임라인 게시글 리스트
 * @param {string} userId - 유저 아이디
 * @param {number} offset - 총 게시글 수
 * @returns {function} - 유저 타임라인 게시글 리스트 결과
 */
export const apiGetTimeline = async (
    userId?: string,
    offset: number = 0,
    props?: IApiBaseProps,
): Promise<ApiRes<TPost[]>> => {
    return await apiGet(`timeline/${userId}?o=${offset}`, {cookies: props?.cookie})
}

/**
 * 타임라인 게시글 리스트
 * @param {FeedType} type - 타임라인 종류
 * @param {string} channel - 한국 - ko, 해외 - all
 * @param {string} skip - 다음 게시글
 * @param {number} limit - 게시글 리밋
 * @returns {function} - 타임라인 게시글 리스트 결과
 */
export const apiGetFeeds = async (
    type: FeedType = Feed.All,
    channel?: TimelineChannel,
    skip: string = '',
    limit: number = 2,
): Promise<ApiRes<TFeedList>> => {
    switch (type) {
        case Feed.All:
            const _channel = !channel ? '' : `&c=${channel}`
            return await apiGet(`feed/all?n=${skip}${_channel}`)
        case Feed.Featured:
            return await apiGet(`feed/featured?limit=${limit}&c=${channel}`)
        case Feed.Subscribed:
            return await apiGet(`feed/subscribed?n=${skip}&c=${channel}`)
    }
}

/**
 * 타임라인 상세 게시글
 * @param {string} id - 타임라인 아이디
 * @returns {function} - 타임라인 상세 게시글 결과
 */
export const apiGetPost = async (id: string, props?: IApiBaseProps): Promise<ApiRes<TPost>> => {
    return await apiGet(`post/${id}`, {cookies: props?.cookie})
}

/**
 * 타임라인 인기글 리스트
 * @returns {function} - 타임라인 인기글 리스트 결과
 */
export const apiGetTrendingPosts = async (language?: string): Promise<ApiRes<TPostTrend[]>> => {
    return await apiGet(`posts/trending`, {cookies: {language}})
}

/**
 * 게시글 작성
 * @param {WritePostParams} params - 게시글 작성 파라미터
 * @returns {function} - 게시글 작성 결과
 */
export const apiWritePost = async (params: WritePostParams): Promise<ApiRes<TPostRes>> => {
    return await apiPost(`post`, params)
}

/**
 * 게시글 수정
 * @param {WritePostParams} params - 게시글 수정 파라미터
 * @returns {function} - 게시글 수정 결과
 */
export const apiEditPost = async (params: WritePostParams): Promise<ApiRes<SuccessRes>> => {
    return await apiPost(`epost`, params)
}

/**
 * 게시글 좋아요
 * @param {string} parent_id - 게시글 id 파라미터
 * @returns {function} - 게시글 좋아요 결과
 */
export const apiLikePost = async (parent_id: string): Promise<ApiRes<TPostLikeRes>> => {
    return await apiPost(`like`, {parent_id})
}

/**
 * 게시글 좋아요 취소
 * @param {string} parent_id - 게시글 id 파라미터
 * @returns {function} - 게시글 좋아요 취소 결과
 */
export const apiDislikePost = async (parent_id: string): Promise<ApiRes<TPostLikeRes>> => {
    return await apiDelete(`like/${parent_id}`)
}

/**
 * 게시글 삭제
 * @param {string} post_id - 게시글 id 파라미터
 * @returns {function} - 게시글 삭제 결과
 */
export const apiDeletePost = async (post_id: string): Promise<ApiRes<SuccessRes>> => {
    return await apiDelete(`post/${post_id}`)
}

/**
 * 게시글 신고
 * @param {string} parent_id - 게시글 id 파라미터
 * @param {string} reportReason - 신고 이유
 * @returns {function} - 게시글 신고 결과
 */
export const apiReportPost = async (parent_id: string, reportReason: string): Promise<ApiRes<SuccessRes>> => {
    return await apiPost('report', {parent_id, extra: reportReason})
}
