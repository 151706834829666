import React, {useRef, useState} from 'react'
import {IBaseProps} from '@type/page'
import {
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole,
    arrow,
    FloatingArrow,
} from '@floating-ui/react'

import {WMOption} from '@component/menu/WizMenu/WizMenu'
import WizMenuItem from '@component/menu/items/WizMenuItem'
import {Placement} from '@floating-ui/utils'

interface IProps extends IBaseProps {
    innerClassName?: string
    items?: WMOption[]
    customHeader?: React.ReactNode
    children?: React.ReactNode
    floatingStyle?: React.CSSProperties
    placement?: Placement
    enabled?: boolean
    enableHover?: boolean
    enableArrow?: boolean
}

const GAP = 3

const WizCustomMenu: React.FC<IProps> = ({
    className,
    innerClassName,
    customHeader,
    children,
    items,
    floatingStyle,
    placement,
    enabled = true,
    enableHover = false,
    enableArrow = false,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const arrowRef = useRef(null)
    const {x, y, strategy, refs, context} = useFloating({
        open: isOpen,
        placement: placement || 'bottom-start',
        onOpenChange: setIsOpen,
        middleware: [flip(), shift(), offset(GAP), arrow({element: arrowRef})],
        whileElementsMounted: autoUpdate,
    })

    const click = useClick(context, {enabled: !enableHover})
    const hover = useHover(context, {
        enabled: enableHover,
        handleClose: safePolygon({
            requireIntent: false,
        }),
    })
    const dismiss = useDismiss(context)
    const role = useRole(context)
    const {getReferenceProps, getFloatingProps} = useInteractions([hover, click, dismiss, role])

    const onClose = (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.preventDefault()
        e?.stopPropagation()
        setIsOpen(false)
    }

    return (
        <div className={`${className}`}>
            <div
                ref={refs?.setReference}
                {...getReferenceProps({
                    onClick: e => {
                        e.preventDefault()
                        e.stopPropagation()
                    },
                })}>
                {children}
            </div>
            {enabled && isOpen && (
                <div
                    ref={refs?.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content',
                        ...floatingStyle,
                    }}
                    className={'z-tooltip cursor-pointer'}
                    onClick={onClose}
                    {...getFloatingProps()}>
                    <div
                        className={`border rounded-[5px] shadow-normal bg-white dark:bg-bg_dark_popup dark:shadow-dark_normal border-gray06 dark:border-dark_gray06 ${innerClassName}`}>
                        {customHeader}
                        {items?.map(item => (
                            <WizMenuItem key={item?.id} {...item} onClose={onClose} />
                        ))}
                    </div>
                    {enableArrow && (
                        <FloatingArrow
                            ref={arrowRef}
                            context={context}
                            className={'fill-white dark:fill-bg_dark_popup'}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default WizCustomMenu
