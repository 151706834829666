import {useQuery} from '@tanstack/react-query'
import {natsClient} from '@component/nats/nats'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {TChartIndicators} from '@api/chart/chart'
import {useNatsConnectionStore} from '@component/nats/connection'
import {calculateReconnectInterval} from '@util/interval'

export const useNatsGetChartIndicator = (enabled = false) => {
    const isConnected = useNatsConnectionStore(state => state.isConnected)
    return useQuery({
        queryKey: QueryCacheKeys.nats.getChartIndicator(),
        staleTime: 60 * 1000,
        enabled: isConnected && enabled,
        retry: true,
        retryDelay: attemptIndex => calculateReconnectInterval(attemptIndex),
        queryFn: async () => {
            const data = await natsClient.getChartIndicatorInfo()
            if (data) return data
            return {} as TChartIndicators
        },
    })
}
