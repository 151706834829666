import {SuccessRes} from '@type/common'

export const Feed = {
    All: 'all',
    Featured: 'featured',
    Subscribed: 'subscribed',
} as const
export type FeedType = (typeof Feed)[keyof typeof Feed]

export const PostVisibility = {
    Private: 0,
    Public: 1,
} as const
export type PostVisibilityType = (typeof PostVisibility)[keyof typeof PostVisibility]

export type TimelineChannel = 'en' | 'ko' | 'all'

export type TWriter = {
    id?: string
    name?: string
    thumbnail?: string
    is_official?: boolean
    status?: string
    asset_rating?: string
    type?: number
    is_pro?: boolean
}

// Timeline Post Types
export type TPost = {
    id: string
    user_id: string
    title?: string
    content?: string
    images?: string[]
    user: TWriter
    is_liked: boolean
    is_pinned: boolean
    likes_count: number
    comments_count: number
    created_at: number
    visibility: PostVisibilityType
    language: string
    channel?: TimelineChannel
    type: string
    views: number
}
export type TPostTrend = Pick<TPost, 'id' | 'user_id' | 'language'> & {
    post_id: string
    text: string
    rank: number
    nickname: string
    is_pro?: boolean
    user_thumbnail?: string
    like_count: number
    click_count: number
    post_created_at: string
}

export type TFeedList = {
    data: TPost[]
    next: string
}

// Write Post Types
export type WritePostParams = {
    id?: string //used only on edit
    title?: string
    content?: string
    images?: string[]
    channel?: TimelineChannel
    isChangedChannel?: boolean
    visibility?: PostVisibilityType
    isChangedVisibility?: boolean
    type: 'post' | 'signal' | 'news'
    writer?: TWriter
}

// Write Post Result Types
export type TPostRes = SuccessRes & {
    post: TPost
    point?: number
}

// Like Post Result Types
export type TPostLikeRes = SuccessRes & {
    point?: number
}

// Comment Types
export type TComment = {
    user: TWriter
    is_liked?: boolean
    id: string
    user_id: string
    parent_id: string
    mention_user_id?: string
    mentioned_username?: string
    content: string
    likes_count?: number
    comments_count?: number
    created_at: number
}

export type TCreateCommentParams = {
    parent_id?: string
    content?: string
    mention_user_id?: string
}

export type TCreateCommentRes = SuccessRes & {
    comment: TComment
}
