import React, {useMemo} from 'react'
import classNames from 'classnames'
import {AnimatePresence, motion} from 'framer-motion'
import {Listbox, ListboxOptions} from '@headlessui/react'

import {WSOption} from '@component/select/WizSelectBox'

import WizSelectBoxItem from '@component/select/item/WizSelectBoxItem'
import WizTextSelectButtonItem from '@component/select/item/WizTextSelectButtonItem'

interface IProps {
    className?: string
    buttonClassName?: string
    optionClassName?: string
    disabled?: boolean
    selectedOption: string | number
    options?: Array<WSOption>
    onChange?: (option?: WSOption) => void
    leftIcon?: React.ReactNode
}

const WizTextSelectBox: React.FC<IProps> = ({
    className,
    buttonClassName,
    optionClassName,
    disabled,
    selectedOption,
    options,
    onChange,
    leftIcon,
}) => {
    const selectedOptionItem = useMemo(() => {
        return options?.find(option => option.id === selectedOption)
    }, [options, selectedOption])
    return (
        <div className={`relative ${className}`}>
            <Listbox value={selectedOption} disabled={disabled}>
                {({open}) => {
                    return (
                        <div className={'relative'}>
                            <WizTextSelectButtonItem
                                className={buttonClassName}
                                selectedOption={selectedOptionItem?.text}
                                options={options}
                                leftIcon={leftIcon}
                            />
                            <AnimatePresence>
                                {open && (
                                    <ListboxOptions
                                        anchor={'bottom start'}
                                        static
                                        modal={false}
                                        as={motion.div}
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1, transition: {duration: 0.1}}}
                                        exit={{opacity: 0, transition: {duration: 0.1}}}
                                        className={classNames(
                                            '[--anchor-gap:5px]',
                                            'absolute origin-top z-dropdown !max-h-[270px] overflow-y-auto rounded-[3px] border',
                                            /* light */ 'shadow-normal bg-white border-gray06',
                                            /* dark */ 'dark:shadow-dark_normal dark:bg-bg_dark_popup dark:border-dark_gray06',
                                            optionClassName,
                                        )}>
                                        {options?.map(option => (
                                            <WizSelectBoxItem
                                                key={option.id}
                                                option={option}
                                                selectedOption={selectedOption}
                                                onClick={() => onChange(option)}
                                            />
                                        ))}
                                    </ListboxOptions>
                                )}
                            </AnimatePresence>
                        </div>
                    )
                }}
            </Listbox>
        </div>
    )
}
export default WizTextSelectBox
