import React from 'react'
import {TToken} from '@type/token'
import {getNumberColorClass} from '@util/etc'

import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'

import TriangleIcon from '@svg/common/ic_triangle_up.svg'

interface IProps {
    className?: string
    rankClassName?: string
    token?: TToken
    logoSize?: number
    onClick?: (base_symbol: string) => void
}

const WizTokenRankCard: React.FC<IProps> = ({className, rankClassName, token, logoSize = 18, onClick}) => {
    return (
        <div
            onClick={() => onClick(token?.symbol)}
            className={`flex items-center gap-x-[7px] group hover:bg-gray08 dark:hover:bg-dark_gray08 hover:rounded-[3px] cursor-pointer transition-colors duration-100 ${className}`}>
            <Text className={`text-ti2 text-gray01 dark:text-dark_gray01 text-center min-w-[17px] ${rankClassName}`}>
                {token?.rank}
            </Text>
            <div className={'flex-1 flex items-center justify-between gap-x-[5px]'}>
                <div className={'flex items-center gap-x-[5px]'}>
                    {token?.logo && (
                        <div className={`w-[${logoSize}px] min-w-[${logoSize}px]`}>
                            <WizImage
                                src={token?.logo}
                                width={logoSize}
                                height={logoSize}
                                imageClassName={'rounded-full'}
                            />
                        </div>
                    )}
                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 group-hover:underline'}>
                        {token?.symbol}
                    </Text>
                </div>
                {token?.rank_change === 0 ? (
                    <Text className={'text-body2 text-gray01 dark:text-dark_gray01'}>-</Text>
                ) : (
                    <div className={'flex items-center justify-center'}>
                        {token?.rank_change > 0 ? (
                            <TriangleIcon className={'fill-primary dark:fill-dark_primary'} />
                        ) : (
                            <TriangleIcon className={'fill-red dark:fill-dark_red rotate-180'} />
                        )}
                        <Text
                            className={`text-body2 text-center dark:text-dark_primary_shade ${getNumberColorClass(
                                token?.rank_change,
                            )}`}>
                            {' '}
                            {token?.rank_change > 0 ? '+' : ''}
                            {token?.rank_change.toString()}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    )
}
export default WizTokenRankCard
