import React from 'react'
import {WMOption} from '@component/menu/WizMenu/WizMenu'

const WizMenuItem: React.FC<WMOption & {onClose: (e?: React.MouseEvent<HTMLDivElement>) => void}> = ({
    value,
    onClick,
    onClose,
}) => {
    const onClickItem = (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.preventDefault()
        e?.stopPropagation()
        onClick?.()
        onClose(e)
    }
    return (
        <div onClick={onClickItem} className={'cursor-pointer'}>
            {value}
        </div>
    )
}
export default React.memo(WizMenuItem)
