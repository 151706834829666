import React, {Ref, useImperativeHandle, useState} from 'react'

import WizModal from '@component/modal/WizModal'
import WizIcon from '@component/images/WizIcon'
import WizImg from '@component/images/WizImg'

import IconModalClose from '@svg/common/ic_modal_close.svg'

export type TWizImageViewerRef = {show: () => void; close: () => void}
interface IProps {
    className?: string
    image: string
}

const WizImageViewer = ({image}: IProps, ref: Ref<TWizImageViewerRef>) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            setIsOpen(true)
        },
        close: () => {
            closeModal()
        },
    }))

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <WizModal className={'h-full relative'} isOpen={isOpen} onClose={closeModal}>
            <WizImg src={image} alt={'image viewer'} />
            <WizIcon
                onClick={closeModal}
                className={'absolute top-[10px] right-[10px]'}
                backgroundColorClassName={'fill-gray02 dark:fill-dark_gray02 cursor-pointer'}>
                <IconModalClose className={'w-[20px] fill-white dark:fill-black'} />
            </WizIcon>
        </WizModal>
    )
}
export default React.forwardRef(WizImageViewer)
