import {apiDelete, apiGet, apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {TComment, TCreateCommentParams, TCreateCommentRes} from '@api/timeline/type'
import {SuccessRes} from '@type/common'

/**
 * 게시글 댓글
 * @param {string} id - 게시글 아이디
 * @returns {function} - 게시글 댓글 결과
 */
export const apiGetComments = async (id: string): Promise<ApiRes<TComment[]>> => {
    return await apiGet(`comments/${id}`)
}

/**
 * 게시글 대댓글
 * @param {string} id - 댓글 아이디
 * @returns {function} - 대댓글 결과
 */
export const apiGetReplies = async (parent_id: string): Promise<ApiRes<TComment[]>> => {
    return await apiGet(`comments/${parent_id}`)
}

/**
 * 게시글 댓글 작성
 * @param {TCreateCommentParams} comment - 댓글
 * @returns {function} - 댓글 작성 결과
 */
export const apiPostCreateComment = async (comment: TCreateCommentParams): Promise<ApiRes<TCreateCommentRes>> => {
    return await apiPost(`comment`, {...comment})
}

/**
 * 댓글 삭제
 * @param {string} id - 댓글 아이디
 * @returns {function} - 댓글 삭제 결과
 */
export const apiDeleteComment = async (id: string): Promise<ApiRes<SuccessRes>> => {
    return await apiDelete(`comment/${id}`)
}
